// eslint-disable-next-line import/prefer-default-export
export default function ordersMenuItems(active, params = {}) {
  const menuItems = [
    // {
    //   name: 'settings-orders-list',
    //   layout: 'Orders',
    // },
    {
      name: 'settings-orders-general-rules',
      layout: 'General Rules',
    },
    {
      name: 'settings-orders-rental',
      layout: ' Rental/Sales Orders',
    },
    {
      name: 'settings-orders-purchase',
      layout: 'Purchase Orders',
    },
    {
      name: 'settings-orders-credit-notes',
      layout: 'Credit Notes',
    },
    {
      name: 'settings-orders-sub-rental',
      layout: 'Sub Rental',
    },
    {
      name: 'settings-orders-service',
      layout: 'Service Orders',
    },
    {
      name: 'settings-orders-transfer-orders',
      layout: 'Transfer Orders',
    },
    // {
    //   name: 'settings-orders-templates',
    //   layout: 'Templates',
    // },
  ]
  menuItems.forEach(item => {
    // eslint-disable-next-line no-param-reassign
    item.active = item.name === active
    // eslint-disable-next-line no-param-reassign
    item.route = { name: item.name, params }
  })

  return {
    menuItems,
  }
}
